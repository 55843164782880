.orders-list-main-box {
	min-height: 75vh;
}
.orders-list-main-box h1 {
	margin-bottom: 1rem;
}

.order-list-border {
	border: 2px solid grey;
	border-radius: 5px;
	margin-bottom: 1rem;
	box-shadow: 8px 8px 26px -12px rgba(66, 68, 90, 1);
	padding: 2rem 4rem;
}

.order-list-details {
	display: flex;
	flex-direction: row;
	padding: 1rem;
	gap: 20px;
}
.order-list-first-box {
	width: 50%;
}
.order-list-second-box {
	display: flex;
	flex-direction: column;
	width: 50%;
}
.order-list-detail {
	display: flex;
	justify-content: space-between;
	text-align: center;
	margin-bottom: 1rem;
}

.orders-list-btn {
	text-align: center;
	color: var(--buttons-hover-color);
	text-decoration: none;
	text-transform: uppercase;
	padding: 0.5rem;
	border-radius: 5px;
}
.order-list-btn-box {
	text-align: center;
}
.orders-list-btn:hover {
	color: #be5459;
	background-color: rgb(225, 225, 225);
}

.order-list-product {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 1rem;
	gap: 20px;
}
.order-img-name-box {
	display: flex;
	flex-direction: row;
}
.order-list-image-box {
	display: flex;
	align-items: center;
	width: 50px;
	height: 50px;
}
.order-list-image-box img {
	width: 100%;
	height: 75%;
	object-fit: scale-down;
}
.order-list-product-name {
	display: flex;
	flex-direction: row;
	text-align: center;
	align-items: center;
	cursor: pointer;
}
.order-review-qty-box {
	display: flex;
}

.order-list-product-review {
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: uppercase;
	margin-right: 1rem;
}
.order-list-product-qty {
	display: flex;
	align-items: center;
	text-transform: uppercase;
	padding: 0.5rem;
}
.order-list-product-review-link {
	text-align: center;
	color: var(--font-color);
	padding: 0.5rem;
	border-radius: 5px;
	text-decoration: none;
}
.order-list-product-review-link:hover {
	background-color: rgb(225, 225, 225);
}

@media screen and (max-width: 976px) {
	.order-list-details {
		flex-direction: column;
		padding: 0;
	}
	.order-list-first-box,
	.order-list-second-box {
		width: 100%;
	}
}
@media screen and (max-width: 600px) {
	.order-list-detail {
		flex-direction: column;
	}
	.order-list-detail h2 {
		font-size: 1rem;
	}
	.order-list-border {
		padding: 1rem;
	}
	.order-list-product-qty {
		display: none;
	}
	.order-list-product-review {
		font-size: 0.8rem;
	}
}
