.navbar-category-search-box {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	margin: 1rem;
	width: 100%;
}
.navbar-search-box {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem;
	width: 100%;
}

.navbar-search-box input {
	height: 80%;
	width: 100%;
	font-size: 1rem;
	padding: 0.3rem;
	padding-left: 0.5rem;
	border: 0.5px solid grey;
	border-right: none;
}

.navbar-search-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80%;
	font-size: 1rem;
	text-transform: uppercase;
	padding: 0.3rem 2rem;
	border: 0.5px solid var(--buttons-color);
	background: var(--buttons-color);
	transition: background 0.3s;
	cursor: pointer;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
.navbar-search-btn:hover {
	background: var(--buttons-hover-color);
}

@media screen and (max-width: 900px) {
	.navbar-category-search-box {
		order: 2;
		margin: 0.5rem 3rem;
	}
	.navbar-search-box {
		margin-left: 0;
	}
	.navbar-search-btn {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
@media screen and (max-width: 550px) {
	.navbar-category-search-box {
		margin: 0.5rem 2rem;
	}
}
@media screen and (max-width: 400px) {
	.navbar-category-search-box {
		margin: 0.5rem 1rem;
	}
	.navbar-search-box input {
		padding-left: 0.3rem;
	}
	.navbar-search-box input::placeholder {
		font-size: 0.9rem;
	}
}
