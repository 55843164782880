.footer-box {
	position: relative;
	width: 100%;
	bottom: -3rem;
	text-align: center;
	background-color: var(--main-color);
	margin: 3rem 0;
	padding: 1rem 0;
	background: black;
	color: white;
}
