.message-box {
	width: 100%;
	left: 0;
	position: relative;
	background-color: var(--buttons-color);
	padding: 1rem 2rem;
	font-size: 24px;
}
@media screen and (max-width: 500px) {
	.message-box {
		font-size: 1rem;
	}
}
