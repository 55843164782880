.cart {
	display: flex;
	justify-content: center;
	gap: 50px;
	min-height: 100vh;
}
.empty-cart {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	gap: 2rem;
	margin-top: 2rem;
	min-height: 100vh;
}
.cart-box {
	padding: 1rem;
}
.process-steps {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}
.process-names {
	display: flex;
	justify-content: space-evenly;
	gap: 30px;
}
.process-name {
	margin-bottom: 0.7rem;
}
.mobile-hide {
	display: block;
}
.process-name-small {
	display: none;
}
.process-lines {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 5px;
}
.prcoess-line {
	width: 100%;
	height: 7px;
	background-color: var(--disabled-color);
}
.line-active {
	background-color: var(--buttons-hover-color);
}
.name-active {
	color: var(--font-color);
	font-weight: bold;
}
.item-box {
	display: flex;
	justify-content: center;
	gap: 20px;
	flex-direction: row;
	padding: 1rem;
	margin-top: 2rem;
	border-bottom: 1px solid gray;
	border: 1px rgba(120, 120, 120, 0.867) solid;
	border-radius: 5px;
	box-shadow: 8px 8px 26px -12px rgba(66, 68, 90, 1);
}
.image-box {
	display: flex;
	align-items: center;
	width: 80px;
	height: 80px;
}
.image-box img {
	width: 100%;
	height: 75%;
	object-fit: scale-down;
}
.product-name {
	display: flex;
	text-align: center;
	align-items: center;
	width: 40%;
	cursor: pointer;
}
.change-amount-box {
	display: flex;
	padding: 0.5rem;
	text-align: center;
	align-items: center;
}
.change-amount-box input {
	padding: 0.2rem;
	text-align: center;
	width: 40px;
	font-size: 16px;
}
.change-amount-box button {
	padding: 0.2rem;
	cursor: pointer;
	font-size: 16px;
}
.price-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 30%;
	gap: 3px;
	align-items: center;
}
.product-total-price {
	font-size: 1.5rem;
	font-weight: bold;
	font-family: var(--bold-text);
}
.product-price {
	font-size: 0.8rem;
	text-align: center;
}
.remove-box {
	display: flex;
	align-items: center;
	text-align: center;
}
.remove-box i {
	cursor: pointer;
}
.summary-box {
	display: flex;
	justify-content: flex-end;
	margin-top: 2rem;
}
.summary-amount {
	font-size: 16px;
}
.summary-box span {
	font-size: 24px;
	font-family: var(--bold-text);
	font-weight: bold;
}
.second-summary-box {
	display: flex;
	flex-direction: column;
	/* justify-content: flex-start; */
	gap: 0.8rem;
	line-height: 26px;
	font-size: 14px;
	margin-left: 1rem;
	/* margin-top: 2rem; */
}
.btn-cart-screen {
	margin-top: 1rem;
}
.link {
	text-decoration: none;
	color: black;
}
@media screen and (max-width: 1024px) {
	.product-total-price {
		font-size: 1.3rem;
	}
}
@media screen and (max-width: 900px) {
	.cart {
		justify-content: flex-start;
		flex-direction: column;
	}
	.second-summary-box {
		align-items: flex-end;
	}
}
@media screen and (max-width: 700px) {
	.item-box {
		justify-content: center;
		flex-wrap: wrap;
		gap: 0px;
		margin-top: 1rem;
	}
	.cart-box {
		padding: 0;
	}
	.change-amount-box {
		flex: 0 0 100%;
		order: 2;
	}
}

@media screen and (max-width: 567px) {
	.item-box {
		padding-bottom: 0.5rem;
	}
	.link {
		font-size: 0.8rem;
	}
	.product-price {
		font-size: 0.7rem;
	}
	.product-total-price {
		font-size: 0.8rem;
	}
	.image-box {
		flex: 0 0 40%;
		justify-content: center;
	}
	.product-name {
		flex: 0 0 40%;
		justify-content: center;
	}
	.remove-box {
		flex: 0 0 10%;
		justify-content: center;
		order: 2;
		margin-bottom: 0.5rem;
	}
	.change-amount-box {
		flex: 0 0 30%;
		justify-content: center;
	}
	.price-box {
		flex: 0 0 30%;
		justify-content: center;
	}
	.cart-add-products-btn {
		position: absolute;
		margin-right: 1rem;
		right: 0;
	}
	.summary-box {
		margin-bottom: 1rem;
	}
	.summary-box span {
		font-size: 1.2rem;
	}

	.mobile-hide {
		display: none;
	}
	.process-name-small {
		display: block;
	}
}
