* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
	font-family: var(--main-text);
}
body {
	background-color: rgb(255, 255, 255);
}
.app {
	min-height: 75vh;
}

.padding-section {
	padding: 2rem 4rem;
}
.margin-section {
	position: relative;
	margin: 2rem 4rem;
}
.box-shadow,
.box-shadow-form {
	border: 1px rgba(196, 195, 195, 0.867) solid;
	border-radius: 5px;
	box-shadow: 8px 8px 8px -12px rgb(172, 172, 172);
}
.box-shadow {
	padding: 1rem;
}

@media screen and (max-width: 667px) {
	.margin-section {
		margin: 2rem 1rem;
	}
}
.btn {
	font-size: 1rem;
	border-radius: 5px;
	border: 1px solid var(--buttons-color);
	padding: 0.5rem 2rem;
	margin-bottom: 1rem;
	background: var(--buttons-color);
	transition: background 0.3s;
	cursor: pointer;
}
.btn:hover {
	background: var(--buttons-hover-color);
}

@media screen and (max-width: 850px) {
	.btn {
		padding: 0.5rem 2rem;
	}
}

@media screen and (max-width: 500px) {
	.btn {
		font-size: 1rem;
	}
}
