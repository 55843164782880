.product-box {
	width: 300px;
	height: 300px;
	border: 1px rgba(120, 120, 120, 0.867) solid;
	border-radius: 5px;
	margin: 1rem;
	padding: 0.5rem;
	box-shadow: 8px 8px 26px -12px rgba(66, 68, 90, 1);
}

.product-box img {
	width: 100%;
	height: 63%;
	object-fit: scale-down;
	cursor: pointer;
}
.link-title{
	text-decoration: none;
	color: black;
}
.title {
	font-size: 0.8rem;
	text-align: center;
	margin: 0 0.3rem;
	margin-bottom: 0.6rem;
	cursor: pointer;
	transition: text-decoration 0.3s;
}
.title:hover {
	text-decoration: underline;
}
.rating {
	text-align: center;
}
.price {
	font-size: 1rem;
	text-align: center;
    margin-top: 0.5rem;
}
@media screen and (max-width: 550px) {
    .title{
        font-size: 14px;
        line-height: 1.2rem;
    }
	.price  {
		font-size: 1.1rem;
	}
}
