.navbar {
	display: flex;
	justify-content: space-between;
	background: var(--main-color);
	width: 100%;
	color: var(--font-color);
	margin-top: 0.5rem;
	border-bottom: 1px solid rgb(184, 184, 184);
}
.navbar span {
	margin-left: 10px;
}
.name {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem;
	margin-left: 3rem;
	padding-bottom: 0.4rem;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 3px;
	color: var(--font-color);
	cursor: pointer;
}

.nav-options {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 40%;
	margin: 1rem;
	margin-right: 2rem;
	padding-bottom: 0.4rem;
	font-size: 1.8rem;
}
.menu-icon {
	font-size: 1.5rem;
}
.link i,
.link span {
	color: var(--font-color);
}

.btn-cart {
	margin: 0 1rem;
	cursor: pointer;
}
.nav-cart-box {
	position: relative;
}
.nav-cart-amount {
	position: absolute;
	background-color: var(--buttons-hover-color);
	top: 10px;
	right: -8px;
	padding: 4px;
	border-radius: 50%;
	padding: 0.2rem 0.4rem;
	font-size: 16px;
	font-style: normal;
}
.my-account {
	position: relative;
}
.fa-user,
.btn-my-account {
	cursor: pointer;
}

.fa-chevron-up,
.fa-chevron-down {
	margin-left: 1rem;
}
@media screen and (max-width: 900px) {
	.name {
		margin-bottom: 0;
	}
	.nav-options {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 545px) {
	.name {
		margin-left: 1rem;
	}
	.menu-icon {
		font-size: 1.3rem;
	}
	.icon-name {
		font-size: 14px;
		display: none;
	}
	.nav-options {
		margin-left: 0;
		margin-right: 1rem;
	}
}
@media screen and (max-width: 675px) {
}
@media screen and (max-width: 900px) {
	.navbar {
		display: flex;
		flex-wrap: wrap;
	}
	.nav-options {
		width: 35%;
	}
}
@media screen and (max-width: 1450px) {
	.icon-name {
		display: none;
	}
}
