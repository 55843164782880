.modal {
	position: fixed;
	overflow: hidden;
	overflow-block: scroll;
	width: 100vw;
	height: 100vh;
}
.backdrop {
	position: fixed;
	background: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	z-index: 10;
	overflow: hidden;
}
.popup {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 11;
	overflow: hidden;
}
.popup-window {
	width: 300px;
	height: 250px;
	background: white;
	border-radius: 5px;
	border: none;
	overflow: hidden;
}
.popup-window-title {
	font-size: 1.3rem;
	text-align: center;
	margin-bottom: 2rem;
	padding: 1rem;
	color: white;
	background: black;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	border: none;
}
.popup-window-body {
	padding: 0 1rem;
}
.popup-window-text {
	margin-bottom: 1rem;
	text-align: center;
}
.popup-window-button-box {
	display: flex;
	justify-content: space-around;
	text-align: center;
}
.popup-window-btn {
	padding: 0.5rem 2rem;
	cursor: pointer;
	background-color: white;
}
.popup-btn-right {
	border-radius: 5px;
	border: 1px solid var(--font-color);
	transition: background 0.5s color 0.5s;
}
.popup-btn-left {
	border-radius: 5px;
	border: 1px solid var(--buttons-color);
	transition: background-color 0.5s color 0.5s;
}
.popup-btn-left:hover {
	background-color: var(--buttons-hover-color);
	color: white;
}
.popup-btn-right:hover {
	background: var(--font-color);
	color: white;
}

.slide-bottom {
	animation: slide-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-bottom {
	0% {
		transform: translateY(-100px);
	}
	100% {
		transform: translateY(0);
	}
}
